.v2-selector-multiple-container {
    width: 787px;
    position: relative;
  }
  .v2-selector-multiple-transfer {
    position: initial;
  }
  
  .v2-selector-multiple-container-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: #404040;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    min-height: 22px;
  }
  
  .v2-selector-multiple-transfer .ant-transfer-list {
    width: 360px;
    height: 484px;
    border: 1px solid #c2c2c2;
    box-sizing: border-box;
    border-radius: 2px;
  }
  
  .is-shorter .v2-selector-multiple-transfer .ant-transfer-list {
    height: 330px;
  }
  
  .is-higher .v2-selector-multiple-transfer .ant-transfer-list {
    height: 330px;
  }
  
  .is-wider {
    width: 820px;
  }
  
  .is-wider .v2-selector-multiple-transfer .ant-transfer-list {
    width: 400px;
  }
  
  .is-narrower .v2-selector-multiple-transfer .ant-transfer-list {
    height: 330px;
  }
  
  .v2-selector-multiple-transfer
    > .ant-transfer-list
    > div.ant-transfer-list-header
    > span.ant-transfer-list-header-selected {
    position: absolute;
    top: 0px;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #404042;
    margin-left: 270px;
  }
  .v2-selector-multiple-transfer
    > .ant-transfer-list
    > div.ant-transfer-list-header {
    display: flex;
    -ms-flex: none;
    flex: none;
    -ms-flex-align: center;
    align-items: center;
    height: 64px;
    padding: 12px 0px;
    margin: 0px 24px;
    margin-bottom: 12px;
    color: rgba(0, 0, 0, 0.65);
    background: transparent;
    border-bottom: 1px solid #c2c2c2;
    border-radius: 2px 2px 0 0;
  }
  
  .v2-selector-multiple-transfer
    > .ant-transfer-list
    > div.ant-transfer-list-header
    > span.ant-transfer-list-header-title {
    position: absolute;
    top: 0px;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #404042;
    margin-left: -20px;
  }
  
  .v2-selector-multiple-transfer
    > .ant-transfer-list
    > .ant-transfer-list-body
    > .ant-transfer-list-body-search-wrapper {
    position: absolute;
    width: 230px;
    top: 27px;
    margin-left: 90px;
  }
  
  .is-wider
    .v2-selector-multiple-transfer
    > .ant-transfer-list
    > .ant-transfer-list-body
    > .ant-transfer-list-body-search-wrapper,
  .is-wider
    .v2-selector-multiple-transfer
    > .ant-transfer-list
    > .ant-transfer-list-body
    > .ant-transfer-list-body-search-wrapper
    > div
    > input {
    width: 270px;
  }
  
  .v2-selector-multiple-transfer
    > .ant-transfer-list
    > .ant-transfer-list-body
    > .ant-transfer-list-body-search-wrapper
    > div
    > input {
    height: 40px;
    width: 230px;
    background: transparent;
    border: 1px solid #c2c2c2;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 40px;
  }
  
  .v2-selector-multiple-transfer
    > .ant-transfer-list
    > .ant-transfer-list-body
    > .ant-transfer-list-body-search-wrapper
    > div
    > span.ant-transfer-list-search-action {
    display: none;
  }
  
  .v2-selector-multiple-transfer
    > .ant-transfer-list
    > div.ant-transfer-list-header
    > span.anticon.anticon-down.ant-dropdown-trigger.ant-transfer-list-header-dropdown {
    margin-left: 16px;
  }
  
  .ant-transfer-list-header .anticon svg {
    font-size: 12px;
    font-weight: 600;
  }
  
  .v2-selector-multiple-transfer
    > .ant-transfer-list
    > .ant-transfer-list-body
    > .ant-transfer-list-content {
    padding-left: 12px;
    padding-bottom: 12px;
  }
  
  .v2-selector-multiple-transfer
    > .ant-transfer-list
    > .ant-transfer-list-body
    > .ant-transfer-list-content
    > .ant-transfer-list-content-item {
    height: 40px;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: #404040;
  }
  
  .v2-selector-multiple-transfer
    > .ant-transfer-list
    > .ant-transfer-list-body
    > .ant-transfer-list-content
    > .ant-transfer-list-content-item
    > span {
    margin-left: 16px;
  }
  .ant-checkbox-checked > .ant-checkbox-inner,
  .ant-checkbox-indeterminate > .ant-checkbox-inner::after,
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner,
  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    background-color: #4e8dd7;
    border-color: #4e8dd7;
  }
  
  .ant-transfer-list-content-item-checked {
    background-color: white;
  }
  .ant-transfer-operation button.ant-btn-sm.ant-btn-icon-only {
    width: 27px;
    height: 27px;
    border-radius: 14px;
    background: #e6f0fd;
    border: 2px solid #4e8dd7;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .tree-disabled .ant-tree-list {
    background: #f5f5f5;
  }
  
  .ant-transfer-operation button.ant-btn-sm.ant-btn-icon-only:disabled {
    background: white;
  }
  .ant-transfer-disabled
    .ant-transfer-operation
    button.ant-btn-sm.ant-btn-icon-only {
    background: #f5f5f5;
  }
  
  .ant-transfer-operation .ant-btn .anticon {
    color: #4e8dd7;
  }
  
  .v2-selector-multiple-transfer input[type='radio'],
  .v2-selector-multiple-transfer input[type='checkbox'] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    border: 1px solid #c2c2c2;
    box-sizing: border-box;
    border-radius: 2px;
  }
  
  .ant-transfer-list-body {
    overflow-y: auto;
  }
  
  .ant-tree-switcher
    + .ant-tree-checkbox
    + .ant-tree-node-content-wrapper
    > .ant-tree-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #404040;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
  }
  .ant-tree-indent
    + .ant-tree-switcher
    + .ant-tree-checkbox
    + .ant-tree-node-content-wrapper
    > .ant-tree-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #404040;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
  }
  
  .ant-tree .ant-tree-treenode {
    height: 40px;
  }
  
  .v2-selector-multiple-transfer-error .ant-transfer-list {
    border: 1px solid red;
  }
  
  .multiple-selector-v2-search-left {
    position: absolute;
    top: 48px;
    left: 120px;
    width: 16px;
  }
  
  .multiple-selector-v2-search-right {
    position: absolute;
    top: 48px;
    left: 525px;
    width: 16px;
  }
  
  .is-wider .multiple-selector-v2-search-right {
    left: 553px;
  }
  
  .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal.ant-tree-node-selected {
    background-color: #FFFFFF;
  }
  
  .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal.ant-tree-node-selected:hover {
    background-color: #f5f5f5;
  }
  
  .ant-tree-treenode.ant-tree-treenode-switcher-open.ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal:hover {
    background-color: #bae7ff;
  }