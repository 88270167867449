.side-bar-logo {
  height: 52px;
  width: 100%;
  margin-top: 35px;
  margin-bottom: 40px;
  object-fit: contain;
}

.side-menu-nav-background {
  background-color: transparent;
}
